<template>
  <div>
    <div
      v-if="layout == 'header'"
      id="map-container"
      class="fullwidth-home-map"
    >
      <gmap-map
        :center="centerLatLng"
        :zoom="9"
        scrollwheel="false"
        style="height: 620px"
      >
        <gmap-marker
          v-for="(marker, index) in markers"
          :key="marker.title"
          :position="marker.position"
          :icon="mapIcon"
        >
          <gmap-info-window
            :opened="marker.infoWindowStatus"
            @closeclick="marker.infoWindowStatus=false"
          >
            <div class="infoBox">
              <div class="map-box">
                <a class="listing-img-container">
                  <img
                    :src="marker.image"
                    alt=""
                  >
                  <div class="listing-item-content">
                    <h3>{{ marker.title }}</h3>
                    <span><small>{{ marker.address }}</small></span>
                  </div>
                </a>
              </div>
            </div>
          </gmap-info-window>
        </gmap-marker>
      </gmap-map>
      <div class="map-banner">
        <div class="main-search-inner">
          <div class="container">
            <div class="row">
              <div class="col-md-12 grid-full-width page-search mb-3">
                <div class="main-search-input mt-0">
                  <div class="col-lg-3 col-sm-12">
                    <div class="form-group">
                      <input
                        type="text"
                        placeholder="What are you looking for?"
                        class="form-control"
                      >
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-12">
                    <div class="form-group">
                      <input
                        type="text"
                        placeholder="Location"
                        class="form-control"
                      >
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-12">
                    <select
                      id="inlineFormCustomSelectPref"
                      class="custom-select my-1 mr-sm-2"
                    >
                      <option selected>
                        Choose...
                      </option>
                      <option value="1">
                        pointer
                      </option>
                      <option value="2">
                        Bar
                      </option>
                      <option value="3">
                        Food Courts
                      </option>
                    </select>
                  </div>
                  <div class="col-lg-3 col-sm-12 text-right">
                    <a class="btn main-search-btn btn-radius btn-lg btn-primary text-white"><span class="btn-inner--text">Search</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="map-div">
         <button style="height:42px;width:45%;margin-left:5%;z-index:10000" class="btn btn-success mb-3 map-button" @click="redoSearch2"> Redo Search </button> 
        <button style="height:42px;width:45%;z-index:10000" class="btn btn-primary mb-3 map-button" @click="this.locateMe"> Locate Me </button> 
     </div>
    
    <div
      v-if="layout == 'sidebar'"
      id="map-container"
      class="sidebar-marker g-map"
      map-type-id="terrain"
     >
      <gmap-map 
        :center="centerLatLng"
        :zoom="14"
        scrollwheel="true"
        @click="mark"
        @idle="idle"
        ref="mapRef"
        
      >
      <GmapMarker
        v-for="(m, index) in markers"
        :key="index"
        :position="m.position"
        :clickable="true"
        :draggable="false"
         :icon="m.icon"
        @click="openInfoWindowTemplate(markers[index], index)"
      />
       <gmap-info-window
        :options="{ maxWidth: 300, maxHeight: 20 }"
        :position="infoWindow.position"
        :opened="infoWindow.open"
        @closeclick="infoWindow.open = false"
      >
        <div v-html="infoWindow.template"></div>
      </gmap-info-window>
      </gmap-map>
    </div>
  </div>
</template>
<script>
export default {
	props: ['layout','coordinates','events','redoSearch','locateMe','type','name'],
	data(){
		return{
      
       infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: ""
      },
			centerLatLng: {
				lat:  parseFloat(this.coordinates.lat),
				lng:  parseFloat(this.coordinates.lng),
			},
			markers : this.markers
		}
	},
  updated(){
    $('.gm-style-iw').css('height', '200px !important');
  },
	mounted(){
		this.setEventsMarkers();

	},
	methods: {
      openInfoWindowTemplate(item, index) {
    
      if (this.infoWindow.template.length > 0) {
        this.infoWindow.template = "";
      }
      if(item.currentEventInfo.slug != null || item.currentEventInfo.slug != null){

        this.infoWindow.template += '<div style="padding-left:5px; margin: 0 auto;height:20px !important;background-color:#ffffff">';
        if (item.currentEventInfo.images)
          this.infoWindow.template +=
            `<div><a href="/${this.type}/${item.currentEventInfo.slug}" target="_blank"><img src='${process.env.VUE_APP_STORAGE_URL + item.currentEventInfo.images[item.currentEventInfo.images.length - 1].image }' style='object-fit:cover;height:150px;width:100%;border-radius:3px'/></a></div>`;
        if (item.currentEventInfo.name)
        this.infoWindow.template +=
          '<div><h6><a style="color:white;" href="/' +
          this.type + '/'+item.currentEventInfo.slug +
          '" target="_blank"><strong style="color:red">' +
          (index + 1) +
          ". " +
          item.currentEventInfo.name.slice(0,20) +
          "</strong></a></h6></div>";
           this.infoWindow.template +=`<div> <a href="/${this.type}/${item.currentEventInfo.slug}" class="btn btn-primary btn-sm" style="color:#5857c7;">${this.name}</a></div>`;
      
          this.infoWindow.template += "</div>";
          this.infoWindow.position = {
            lat: parseFloat(item.position.lat),
            lng: parseFloat(item.position.lng)
          };
           this.infoWindow.open = true;
    }
    },
    idle(){
       const c = this.$refs.mapRef.$mapObject.getCenter();
      const bounds = this.$refs.mapRef.$mapObject.getBounds();

      if (bounds != null) {
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();

        localStorage.setItem("nelat", ne.lat());
        localStorage.setItem("swlat", sw.lat());
        localStorage.setItem("swlng", sw.lng());
        localStorage.setItem("nelng", ne.lng());
      }
       if (c != null) {
        var coordinates = {};
        coordinates.latitude = c.lat();
        coordinates.longitude = c.lng();

        localStorage.setItem("lat", c.lat());
        localStorage.setItem("lng", c.lng());
      }
    },
    mark(event) {
  },
		openInfoWindow(marker){
			marker.infoWindowStatus = true;
		},
    redoSearch2(){
      this.redoSearch();
    },

	setEventsMarkers() {
    console.log('Events are:',this.events);
      let events = this.events;
      let markersArray = [];

      this.markers = [];
      this.center = {
        lat:  parseFloat(this.coordinates.lat),
        lng:  parseFloat(this.coordinates.lng),
      };

      if (Object.keys(events).length > 0) {
        events.forEach(function(event, index) {
          let marker = {
            lat: parseFloat(event.lat),
            lng: parseFloat(event.long),
          };
          markersArray.push({
            position: marker,
            currentEventInfo: event,
            label: "1",
            infoText: "Marker #1",
          });
        });
          let marker = {
            lat: parseFloat(this.coordinates.lat),
            lng: parseFloat(this.coordinates.lng),
          };
          markersArray.push({
            position: marker,
             currentEventInfo: this.coordinates,
            label: "1",
            infoText: "Marker #1",
            icon: '/static/images/user-location.png' 
          });

        this.markers = markersArray;
      }
	 
    },

	}
};
</script>
<style scoped>
@media only screen and (max-width: 991px) {
  .g-map{
    margin-top:-90px !important;
  }
   .map-button{
    margin-bottom: 102px !important;
  }
   .map-div{
    padding-top:17px;
  }
}
@media only screen and (min-width: 991px) {
  .g-map{
    margin-top:100px !important;
  }
  .map-div{
    padding-top:60px;
  }
   
}

</style>


